import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllRegisteredUsers } from "../../../../../../redux/allRegisteredUsers/allRegisteredUsersSlice";
import { orderServices } from "../../../../../../services/orderServices";
import React from "react";
import { userServices } from "../../../../../../services/userServices";
import "./index.scss";
import dayjs from "dayjs";
import DateTimePickerComponent from "../../../../../../components/datePicker";
import { getUserBalance } from "../../../../../../redux/users/userSlice";
export const CreatePositionFields = ({
  user,
  setEditButtonOptions,
  users,
  setOpenModal,
  setUserDetails,
  fetchUsersPayload,
}) => {
  const dispatch = useDispatch();
  const reduxUser = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );

  const loggedInUserRole = reduxUser?.role;
  const loggedInUserName =
    reduxUser?.role !== "admin" &&
    reduxUser?.role !== "head_of_conversion" &&
    reduxUser?.role !== "crm_manager"
      ? reduxUser?.firstName + " " + reduxUser?.lastName
      : "";
  const leverageTypes = [1, 5, 15, 30, 60, 90, 120];
  const assetTypes = [
    "BTCUSDT",
    "ETHUSDT",
    "BNBUSDT",
    "NEOUSDT",
    "LTCUSDT",
    "ADAUSDT",
    "XRPUSDT",
    "EOSUSDT",
    "IOTAUSDT",
    "XLMUSDT",
    "TRXUSDT",
    "DASHUSDT",
    "MATICUSDT",
    "DOGEUSDT",
    "SOLUSDT",
    "CELOUSDT",
    "SHIBUSDT",
    "DOTUSDT",
    "APTUSDT",
    "TONUSDT",
  ];
  const [simulatedOrder, setSimulatedOrder] = React.useState({
    createdAt: "",
    openPrice: "",
    assetType: "BTCUSDT",
    quantity: "",
    type: "market",
    takeProfit: "",
    stopLoss: "",
    leverage: 1,
    buyOrSell: "buy",
    investmentAmount: "",
    closedAt: "",
    closedPrice: "",
    profit: "",
  });
  const [createdAt, setCreatedAt] = React.useState(
    dayjs(
      new Date().toLocaleString("sv-SE", {
        timeZone: "Europe/Stockholm",
      })
    )
  );
  const [closedAt, setClosedAt] = React.useState(
    dayjs(
      new Date().toLocaleString("sv-SE", {
        timeZone: "Europe/Stockholm",
      })
    )
  );
  const [chosenUsers, setChosenUsers] = React.useState({
    availableUsers: [],
  });
  const availableUsers = useSelector(
    (state) => state.user.user.foundUser.availableUsers
  );
  const handleUserChange = (e) => {
    const {
      target: { value, name },
    } = e;
    setChosenUsers({
      ...chosenUsers,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };
  function perIncrease(closedPrice, openedPrice, buyOrSell) {
    let increasedNumbBuy = Number(closedPrice) - Number(openedPrice);
    let increasedNumbSell = Number(openedPrice) - Number(closedPrice);
    return buyOrSell.toLowerCase() === "buy"
      ? 100 * (increasedNumbBuy / Number(closedPrice))
      : 100 * (increasedNumbSell / Number(closedPrice));
  }
  function investmentAmountPer(investmentAmount, percentage) {
    return (Number(investmentAmount) / 100) * Number(percentage);
  }

  let diffBetweenTwoNumbersInPercent;
  diffBetweenTwoNumbersInPercent = perIncrease(
    simulatedOrder.closedPrice,
    simulatedOrder.openPrice,
    simulatedOrder.buyOrSell
  );
  const investmentAmountPercentage = investmentAmountPer(
    simulatedOrder?.investmentAmount,
    diffBetweenTwoNumbersInPercent
  );
  const profitBeforeCheck =
    investmentAmountPercentage * Number(simulatedOrder.leverage).toFixed(2);
  let profit = isNaN(profitBeforeCheck) ? "---" : profitBeforeCheck.toFixed(2);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSimulatedOrder({
      ...simulatedOrder,
      [e.target.name]: value,
    });
  };

  const toTimeStamp = (strDate) => {
    let date = Date.parse(strDate);
    return date;
  };

  const handleSaveMultipleOrders = () => {
    orderServices
      .createMultipleSimulatedOrders({
        createdAt:
          typeof createdAt === "object" ? Date.parse(createdAt) : createdAt,
        openPrice: simulatedOrder.openPrice,
        assetType: simulatedOrder.assetType,
        quantity: simulatedOrder.quantity,
        leverage: simulatedOrder.leverage,
        stopLoss: simulatedOrder.stopLoss,
        takeProfit: simulatedOrder.takeProfit,
        type: simulatedOrder.type,
        buyOrSell: simulatedOrder.buyOrSell,
        investmentAmount: simulatedOrder.investmentAmount,
        closedAt:
          typeof closedAt === "object" ? Date.parse(closedAt) : closedAt,
        closedPrice: simulatedOrder.closedPrice,
        profit: profit,
        userIds: chosenUsers.availableUsers,
      })
      .then(() => {
        userServices
          .changeMultipleUsersBallance({
            userIds: chosenUsers.availableUsers,
            profit: profit,
          })
          .then(() => {
            dispatch(getUserBalance(user.id));
            setUserDetails({
              ballance: user.ballance,
            });
            setOpenModal(false);
          });
        setEditButtonOptions("");
      });
  };
  const handleOnSave = () => {
    orderServices
      .createSimulatedOrder(user.id, {
        createdAt:
          typeof createdAt === "object" ? Date.parse(createdAt) : createdAt,
        openPrice: simulatedOrder.openPrice,
        assetType: simulatedOrder.assetType,
        quantity: simulatedOrder.quantity,
        leverage: simulatedOrder.leverage,
        stopLoss: simulatedOrder.stopLoss,
        takeProfit: simulatedOrder.takeProfit,
        type: simulatedOrder.type,
        buyOrSell: simulatedOrder.buyOrSell,
        investmentAmount: simulatedOrder.investmentAmount,
        closedAt:
          typeof closedAt === "object" ? Date.parse(closedAt) : closedAt,
        closedPrice: simulatedOrder.closedPrice,
        profit: profit,
      })
      .then(() => {
        dispatch(getAllRegisteredUsers(fetchUsersPayload));
      });
    userServices
      .changeUserBallance(user.id, {
        ballance: (Number(user.ballance) + Number(profit))
          .toFixed(2)
          .toString(),
      })
      .then(() => {
        dispatch(getUserBalance(user.id));
        setUserDetails({
          ballance: user.ballance,
        });
        setOpenModal(false);
      });
    setEditButtonOptions("");
  };
  return (
    <div className="create-position-container">
      <div>
        <DateTimePickerComponent
          label={"Created At"}
          date={createdAt}
          setDate={setCreatedAt}
        />
        <TextField
          className="edit-user-textField"
          label="Open Price"
          onChange={(e) => handleInputChange(e)}
          name="openPrice"
          style={{ margin: "12px 2px", width: "150px" }}
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />
        <FormControl style={{ width: "150px" }}>
          <InputLabel style={{ color: "white", marginTop: "3%" }}>
            Asset Type
          </InputLabel>
          <Select
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              height: "36px",
              margin: "12px 2px",
              backgroundColor: "#1F2A40",
              // border: "1px solid gray",
              borderRadius: "2px",
            }}
            label="Asset Type"
            name="assetType"
            defaultValue={"BTCUSDT"}
            onChange={(e) => handleInputChange(e)}
          >
            {assetTypes.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          className="edit-user-textField"
          label="Quantity"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          name="quantity"
          style={{ margin: "12px 2px", width: "150px" }}
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />

        <FormControl style={{ width: "150px" }}>
          <InputLabel style={{ color: "white", marginTop: "3%" }}>
            Leverage
          </InputLabel>
          <Select
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              height: "36px",
              margin: "12px 2px",
              backgroundColor: "#1F2A40",
              // border: "1px solid gray",
              borderRadius: "2px",
            }}
            label="Leverage"
            name="leverage"
            defaultValue={1}
            onChange={(e) => handleInputChange(e)}
          >
            {leverageTypes.map((leverage) => (
              <MenuItem value={leverage}>{leverage}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          className="edit-user-textField"
          label="Stop Loss"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          style={{ margin: "12px 2px", width: "150px" }}
          name="stopLoss"
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />
      </div>
      <div>
        <TextField
          className="edit-user-textField"
          label="Take Profit"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          name="takeProfit"
          style={{ margin: "12px 2px", width: "150px" }}
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />
        <FormControl style={{ width: "150px" }}>
          <InputLabel style={{ color: "white", marginTop: "3%" }}>
            Order Type
          </InputLabel>
          <Select
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              height: "36px",
              margin: "12px 2px",
              backgroundColor: "#1F2A40",
              // border: "1px solid gray",
              borderRadius: "2px",
            }}
            label="Order Type"
            name="type"
            defaultValue={"market"}
            onChange={(e) => handleInputChange(e)}
          >
            {["market", "limit"].map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={{ width: "150px" }}>
          <InputLabel style={{ color: "white", marginTop: "3%" }}>
            Buy or Sell
          </InputLabel>
          <Select
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              height: "35px",
              margin: "12px 2px",
              backgroundColor: "#1F2A40",
              // border: "1px solid gray",
              borderRadius: "2px",
            }}
            label="Buy or Sell"
            name="buyOrSell"
            defaultValue={"buy"}
            onChange={(e) => handleInputChange(e)}
          >
            {["buy", "sell"].map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          className="edit-user-textField"
          label="Investment Amount"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          style={{ margin: "12px 2px", width: "150px" }}
          name="investmentAmount"
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />
        <DateTimePickerComponent
          label={"Closed At"}
          date={closedAt}
          setDate={setClosedAt}
        />
        <TextField
          className="edit-user-textField"
          label="Closed Price"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          style={{ margin: "12px 2px", width: "150px" }}
          name="closedPrice"
          placeholder="Ex: 1.1.2020 00:00:00"
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />
      </div>
      <TextField
        className="edit-user-textField"
        label="Profit"
        onChange={(e) => handleInputChange(e)}
        variant="outlined"
        name="profit"
        style={{ margin: "12px auto", width: "150px" }}
        value={profit}
        InputLabelProps={{
          style: { color: "white", margin: "-5% auto" },
        }}
      />
      {chosenUsers.availableUsers.length > 0 ? (
        <Button
          style={{ width: "150px", margin: "0 auto", marginBottom: "2%" }}
          color="success"
          variant="outlined"
          onClick={() => handleSaveMultipleOrders()}
        >
          Save Multiple Orders
        </Button>
      ) : (
        <Button
          style={{ width: "150px", margin: "0 auto", marginBottom: "2%" }}
          color="success"
          variant="outlined"
          onClick={() => handleOnSave()}
        >
          Save Order
        </Button>
      )}
      <>
        <p style={{ color: "red", margin: "0 auto" }}>
          Want to open this order to multiple clients?
        </p>
        <FormControl
          sx={{ m: 1, minWidth: 120, width: "200px", margin: "1rem auto" }}
        >
          <InputLabel style={{ color: "white", fontSize: "1rem" }}>
            Available Clients
          </InputLabel>
          <Select
            name="availableUsers"
            multiple
            inputProps={{
              style: {
                color: "white",
              },
            }}
            value={chosenUsers.availableUsers}
            label="Available Clients"
            onChange={handleUserChange}
            style={{ backgroundColor: "#1F2A40", color: "white" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
          >
            {availableUsers?.map((user, i) => (
              <MenuItem key={user?.id} value={user.id}>
                {user.firstName + " " + user.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {chosenUsers.availableUsers.length > 0 && (
          <p
            style={{
              color: "white",
              margin: "0 auto",
            }}
          >
            Chosen clients:
            {availableUsers
              .filter((user) => chosenUsers.availableUsers.includes(user?.id))
              ?.map((user, index) => (
                <p
                  style={{
                    color: "gray",
                    marginBottom: "0.5rem",
                  }}
                >
                  {index + 1 + ". " + user.firstName + " " + user.lastName}
                </p>
              ))}
          </p>
        )}
      </>
    </div>
  );
};
