import { Box } from "@material-ui/core";
import React from "react";
import "./index.scss";
function AmlPolicyPage() {
  return (
    <Box className="aml-policy-page-container">
      <Box>
        <h1 className="aml-policy-page-header">AML Policy</h1>
      </Box>
      <Box className="aml-policy-page-textBox">
        <span className="aml-policy-page-span">
          The objective of Anti-Money laundering procedures that Pier Point
          Global implements is to ensure that customers engaging in certain
          activities are identified to a reasonable standard, while minimizing
          the compliance burden and impact on legitimate customers. MONEY
          LAUNDERING is the act of converting money or other monetary
          instruments gained from illegal activity into money or investments
          that appear to be legitimate, so that its illegal source cannot be
          traced. Domestic and international laws that apply to companies, whose
          customers can deposit and withdraw funds from their accounts, make it
          illegal for Pier Point Global, or its employees or agents, to
          knowinggly engage, or attempt to engage in a monetary transaction of
          criminally derived property. IMPLEMENTED PROCEDURES The objective of
          Anti-Money laundering procedures that Pier Point Global implements is
          to ensure that customers engaging in certain activities are identified
          to a reasonable standard, while minimizing the compliance burden and
          impact on legitimate customers. Pier Point Global is committed to
          assisting governments combat the threat of money laundering and
          financing terrorist activities around the world. For that purpose Pier
          Point Global has set up a highly sophisticated electronic system. This
          system documents and verifies client identification records, and
          tracks and maintains detailed records of all transactions. Pier Point
          Global carefully tracks suspicious and significant transaction
          activities, and reports such activities “providing timely and
          comprehensive information” to law enforcement bodies. To uphold the
          integrity of reporting systems and to safeguard businesses, the
          legislative framework provides legal protection to providers of such
          information. In order to minimize the risk of money laundering and
          financing terrorist activities, Pier Point Global neither accepts cash
          deposits nor disburses cash under any circumstances. Pier Point Global
          reserves the right to refuse to process a transfer at any stage, where
          it believes the transfer to be connected in any way to money
          laundering or criminal activity. For the avoidance of any doubt, Pier
          Point Global may detain or postpone payments for a set period of time
          at its own discretion. It is forbidden for Pier Point Global to inform
          customers that they have been reported for suspicious activity.
          ADDITIONAL DISCLOSURES For the purpose of complying with Anti-Money
          laundering laws, Pier Point Global requires two different documents to
          verify the identity of the customer. The first document we require is
          a legal government-issued, identifying document with the picture of
          the customer on it. It may be a government-issued passport, driver’s
          license (for countries where the driver’s license is a primary
          identification document) or local ID card (no company access cards).
          The second document we require is a bill with the customer’s own name
          and actual address on it issued 3 months ago at the earliest. It may
          be a utility bill, a bank statement, an affidavit, or any other
          document with the customer’s name and address from an internationally
          recognized organization. Pier Point Global also requires a completed
          and physically-signed account application form to be submitted to the
          company. Customers are required to submit up to date identification
          and contact information in a timely manner, as soon as changes occur.
          Documents in non-western letters must be translated into English by an
          official translator; the translation must be stamped and signed by the
          translator and sent together with the original document with a clear
          picture of the customer on it. DEPOSITS AND WITHDRAWALS Pier Point
          Global requires all deposits, where the name of the originating
          customer is present, to come from the name matching the name of the
          customer in our records. Third party payments are not accepted. As for
          withdrawals, money may be withdrawn from the same account and by the
          same way it was received. For withdrawals where the name of the
          recipient is present, the name must exactly match the name of the
          customer in our records. If the deposit was made by wire transfer,
          funds may be withdrawn only by wire transfer to the same bank and to
          the same account from which it originated. If the deposit was made by
          means of electronic currency transfer, funds may be withdrawn only by
          the means of electronic currency transfer through the same system and
          to the same account from which it originated.
        </span>
      </Box>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Box>
  );
}

export default AmlPolicyPage;
