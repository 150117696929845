import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getCryptoSymbolData from "../../../../redux/cryptoMarketData/action";
import DropdownItem from "../CollapseBox";
import { StarBorder } from "@material-ui/icons";
import useWebSocket from "react-use-websocket";
import DogeCoinWebsocket from "./dogeCoingWebSocket";
import "./index.scss";
import BtcWebSocket from "./btcWebSocket";
import EthWebSocket from "./ethWebSocket";
import BnbWebSocket from "./bnbWebSocket";
import NeoWebSocket from "./neoWebSocket";
import LtcWebSocket from "./ltcWebSocket";
import AdaWebSocket from "./adaWebSocket";
import XrpWebSocket from "./xrpWebSocket";
import EosWebSocket from "./eosWebSocket";
import IotaWebSocket from "./iotaWebSocket";
import XlmWebSocket from "./xlmWebSocket";
import TrxWebSocket from "./trxWebSocket";
import DashWebSocket from "./dashWebSocket";
import MaticWebSocket from "./maticWebSocket";
import SolWebSocket from "./solWebSocket";
import { userServices } from "../../../../services/userServices";
import { getUser } from "../../../../redux/users/userSlice";
import CeloWebSocket from "./celoWebSocket";
import ShibaWebSocket from "./shibaWebSocket";
import PolkaDotWebSocket from "./polkaDotWebSocket";
import TonWebSocket from "./tonWebSocket";
import AptWebSocket from "./aptWebSocket";

function CryptocurrenciesWebSocket() {
  const [activeSection, setActiveSection] = useState("crypto");

  const socketUrl = "wss://stream.binance.com:9443/stream";
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(socketUrl);
  const messageHistory = React.useRef([]);
  messageHistory.current = React.useMemo(
    () => messageHistory.current.concat(lastJsonMessage ?? []),
    [lastJsonMessage]
  );

  const handleClickSendMessageDoge = React.useCallback(
    () =>
      sendJsonMessage({
        method: "SUBSCRIBE",
        params: ["dogebusd@ticker"],
        id: 1,
      }),
    [sendJsonMessage]
  );

  const handleClickSendMessageBtc = React.useCallback(
    () =>
      sendJsonMessage({
        method: "SUBSCRIBE",
        params: ["btcbusd@ticker"],
        id: 1,
      }),
    [sendJsonMessage]
  );

  React.useEffect(() => {
    handleClickSendMessageDoge();
    handleClickSendMessageBtc();
  }, []);

  const dispatch = useDispatch();
  const onClickSymbolChange = React.useCallback(
    (payload) => {
      dispatch(getCryptoSymbolData(payload));
    },

    []
  );

  const symbol = useSelector((state) => state.cryptoMarketDataReducer.symbol);
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );

  const favorites = user?.favourites?.map((a) => a.symbol);

  const updateFavourites = (f) => {
    userServices
      .updateUserFavourites({
        userId: user.id,
        symbol: f,
      })
      .then(() => dispatch(getUser(user.id)));
  };

  return (
    <div className="crypto-container">
      <div className="crypto-menu">
        <div className="crypto-buttons">
          <button
            className={
              activeSection === "favourites"
                ? "crypto-button-active"
                : "crypto-button"
            }
            onClick={() => setActiveSection("favourites")}
          >
            Favourites
          </button>
          <button
            className={
              activeSection === "crypto"
                ? "crypto-button-active"
                : "crypto-button"
            }
            onClick={() => setActiveSection("crypto")}
          >
            Crypto
          </button>
          <button
            className={
              activeSection === "stocks"
                ? "crypto-button-active"
                : "crypto-button"
            }
            onClick={() => setActiveSection("stocks")}
          >
            Stocks
          </button>
          <button
            className={
              activeSection === "indicies"
                ? "crypto-button-active"
                : "crypto-button"
            }
            onClick={() => setActiveSection("indicies")}
          >
            Indicies
          </button>
          <button
            className={
              activeSection === "forex"
                ? "crypto-button-active"
                : "crypto-button"
            }
            onClick={() => setActiveSection("forex")}
          >
            Forex
          </button>
        </div>
        {/* Favourites */}
        {activeSection === "favourites" ? (
          <>
            {favorites.indexOf("BTCUSDT") >= 0 && (
              <div
                className={
                  symbol === "BTCUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("BTCUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/bitcoin.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">BTC/USD</div>
                </div>

                <div className="crypto-price-star-wrap">
                  <BtcWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("BTCUSDT")} />
                  </div>
                </div>
              </div>
            )}
            {favorites.indexOf("ETHUSDT") >= 0 && (
              <div
                className={
                  symbol === "ETHUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("ETHUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/etherium.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">ETH/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <EthWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("ETHUSDT")} />
                  </div>
                </div>
              </div>
            )}

            {favorites.indexOf("NEOUSDT") >= 0 ? (
              <div
                className={
                  symbol === "NEOUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("NEOUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/neo.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">NEO/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <NeoWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("NEOUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("BNBUSDT") >= 0 ? (
              <div
                className={
                  symbol === "BNBUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("BNBUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/bnb.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">BNB/USD</div>
                </div>

                <div className="crypto-price-star-wrap">
                  <BnbWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("BNBUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("LTCUSDT") >= 0 ? (
              <div
                className={
                  symbol === "LTCUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("LTCUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/ltc.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">LTC/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <LtcWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("LTCUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("ADAUSDT") >= 0 ? (
              <div
                className={
                  symbol === "ADAUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("ADAUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/ada.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">ADA/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <AdaWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("ADAUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("XRPUSDT") >= 0 ? (
              <div
                className={
                  symbol === "XRPUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("XRPUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/xrp.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">XRP/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <XrpWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("XRPUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("EOSUSDT") >= 0 ? (
              <div
                className={
                  symbol === "EOSUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("EOSUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/eos.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">EOS/USD</div>
                </div>

                <div className="crypto-price-star-wrap">
                  <EosWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("EOSUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("IOTAUSDT") >= 0 ? (
              <div
                className={
                  symbol === "IOTAUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("IOTAUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/iota.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">IOTA/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <IotaWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("IOTAUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("XLMUSDT") >= 0 ? (
              <div
                className={
                  symbol === "XLMUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("XLMUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/xlm.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">XLM/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <XlmWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("XLMUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("TRXUSDT") >= 0 ? (
              <div
                onClick={() => onClickSymbolChange("TRXUSDT")}
                className={
                  symbol === "TRXUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/trx.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">TRX/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <TrxWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("TRXUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
            {favorites.indexOf("DASHUSDT") >= 0 ? (
              <div
                onClick={() => onClickSymbolChange("DASHUSDT")}
                className={
                  symbol === "DASHUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/dash.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">DASH/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <DashWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("DASHUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
            {favorites.indexOf("MATICUSDT") >= 0 ? (
              <div
                onClick={() => onClickSymbolChange("MATICUSDT")}
                className={
                  symbol === "MATICUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/matic.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">MATIC/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <MaticWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("MATICUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
            {favorites.indexOf("DOGEUSDT") >= 0 ? (
              <div
                onClick={() => onClickSymbolChange("DOGEUSDT")}
                className={
                  symbol === "DOGEUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/doge.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">DOGE/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <DogeCoinWebsocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("DOGEUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
            {favorites.indexOf("SOLUSDT") >= 0 ? (
              <div
                onClick={() => onClickSymbolChange("SOLUSDT")}
                className={
                  symbol === "SOLUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/sol.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">SOL/USD</div>
                </div>

                <div className="crypto-price-star-wrap">
                  <SolWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("SOLUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
            {favorites.indexOf("CELOUSDT") >= 0 ? (
              <div
                onClick={() => onClickSymbolChange("CELOUSDT")}
                className={
                  symbol === "CELOUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/celo.png" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">CELO/USD</div>
                </div>

                <div className="crypto-price-star-wrap">
                  <CeloWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("CELOUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
          </>
        ) : (
          false
        )}

        {/* CRYPTO */}
        {activeSection === "crypto" ? (
          <>
            <div
              className={
                symbol === "BTCUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("BTCUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/bitcoin.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">BTC/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <BtcWebSocket />
                <div className="star-div">
                  <StarBorder
                    className="star-svg"
                    onClick={() => updateFavourites("BTCUSDT")}
                  />
                </div>
              </div>
            </div>

            <div
              className={
                symbol === "ETHUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("ETHUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/etherium.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">ETH/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <EthWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("ETHUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "BNBUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("BNBUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/bnb.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">BNB/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <BnbWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("BNBUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "NEOUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("NEOUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/neo.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">NEO/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <NeoWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("NEOUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "LTCUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("LTCUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/ltc.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">LTC/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <LtcWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("LTCUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "ADAUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("ADAUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/ada.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">ADA/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <AdaWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("ADAUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "XRPUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("XRPUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/xrp.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">XRP/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <XrpWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("XRPUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "EOSUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("EOSUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/eos.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">EOS/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <EosWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("EOSUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "IOTAUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("IOTAUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/iota.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">IOTA/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <IotaWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("IOTAUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "XLMUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("XLMUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/xlm.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">XLM/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <XlmWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("XLMUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("TRXUSDT")}
              className={
                symbol === "TRXUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/trx.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">TRX/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <TrxWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("TRXUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("DASHUSDT")}
              className={
                symbol === "DASHUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/dash.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">DASH/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <DashWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("DASHUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("MATICUSDT")}
              className={
                symbol === "MATICUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/matic.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">MATIC/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <MaticWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("MATICUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("DOGEUSDT")}
              className={
                symbol === "DOGEUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/doge.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">DOGE/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <DogeCoinWebsocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("DOGEUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("SOLUSDT")}
              className={
                symbol === "SOLUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/sol.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">SOL/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <SolWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("SOLUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("CELOUSDT")}
              className={
                symbol === "CELOUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/celo.png" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">CELO/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <CeloWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("CELOUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("SHIBUSDT")}
              className={
                symbol === "SHIBUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/shib.png" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">SHIB/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <ShibaWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("SHIBUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("DOTUSDT")}
              className={
                symbol === "DOTUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/polka.png" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">DOT/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <PolkaDotWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("DOTUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("TONUSDT")}
              className={
                symbol === "TONUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/ton.png" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">TON/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <TonWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("TONUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("APTUSDT")}
              className={
                symbol === "APTUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/apt.png" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">APT/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <AptWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("APTUSDT")} />
                </div>
              </div>
            </div>
          </>
        ) : (
          false
        )}

        {/* STOCKS */}
        {activeSection === "stocks" ? (
          <>
            <div
              className={
                symbol === "GOOGLUSD" ? "stocks-rows-active" : "stocks-rows"
              }
              onClick={() => onClickSymbolChange("GOOGLUSD")}
            >
              <div>
                <img src="/alphabet-inc.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Alphabet Inc.</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={symbol === "GS" ? "stocks-rows-active" : "stocks-rows"}
              onClick={() => onClickSymbolChange("GS")}
            >
              <div>
                <img src="/sachs.svg" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Goldman Sachs</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "AMZN" ? "stocks-rows-active" : "stocks-rows"
              }
              onClick={() => onClickSymbolChange("AMZN")}
            >
              <div>
                <img src="/Amazon_icon.svg" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Amazon Inc.</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "BABA" ? "stocks-rows-active" : "stocks-rows"
              }
              onClick={() => onClickSymbolChange("BABA")}
            >
              <div>
                <img src="/alibaba.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Alibaba Group</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "TSLA" ? "stocks-rows-active" : "stocks-rows"
              }
              onClick={() => onClickSymbolChange("TSLA")}
            >
              <div>
                <img src="/tesla.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Tesla Inc.</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={symbol === "FB" ? "stocks-rows-active" : "stocks-rows"}
              onClick={() => onClickSymbolChange("FB")}
            >
              <div>
                <img src="/facebook.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Facebook Inc.</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={symbol === "BA" ? "stocks-rows-active" : "stocks-rows"}
              onClick={() => onClickSymbolChange("BA")}
            >
              <div>
                <img src="/boeing.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Boeing Co</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "IBM" ? "stocks-rows-active" : "stocks-rows"
              }
              onClick={() => onClickSymbolChange("IBM")}
            >
              <div>
                <img src="/ibm.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">IBM</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "MSFT" ? "stocks-rows-active" : "stocks-rows"
              }
              onClick={() => onClickSymbolChange("MSFT")}
            >
              <div>
                <img src="/microsoft-icon.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Microsoft Corp.</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "CAT" ? "stocks-rows-active" : "stocks-rows"
              }
              onClick={() => onClickSymbolChange("CAT")}
            >
              <div>
                <img src="/cat.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Caterpilar Inc.</div>
              <StarBorder></StarBorder>
            </div>
            <div
              onClick={() => onClickSymbolChange("LMT")}
              className={
                symbol === "LMT" ? "stocks-rows-active" : "stocks-rows"
              }
            >
              <div>
                <img src="/lockhgeadMartin.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Lockheed Martin</div>
              <StarBorder></StarBorder>
            </div>
            <div
              onClick={() => onClickSymbolChange("JEPI")}
              className={
                symbol === "JEPI" ? "stocks-rows-active" : "stocks-rows"
              }
            >
              <div>
                <img src="/morgan.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">JPMorgan Chase</div>
              <StarBorder></StarBorder>
            </div>
            <div
              onClick={() => onClickSymbolChange("WFC")}
              className={
                symbol === "WFC" ? "stocks-rows-active" : "stocks-rows"
              }
            >
              <div>
                <img src="/wells.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Wells Fargo</div>
              <StarBorder></StarBorder>
            </div>
          </>
        ) : (
          false
        )}
        {/* FOREX */}
        {activeSection === "forex" ? (
          <>
            <div
              className={
                symbol === "EURUSD" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("EURUSD")}
            >
              <div className="forex-name">EUR/USD</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "GBPUSD" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("GBPUSD")}
            >
              <div className="forex-name">GBP/USD</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "GBPCAD" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("GBPCAD")}
            >
              <div className="forex-name">GBP/CAD</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "AUDCAD" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("AUDCAD")}
            >
              <div className="forex-name">AUD/CAD</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "AUDJPY" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("AUDJPY")}
            >
              <div className="forex-name">AUD/JPY</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "NZDCHF" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("NZDCHF")}
            >
              <div className="forex-name">NZD/CHF</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "USDJPY" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("USDJPY")}
            >
              <div className="forex-name">USD/JPY</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "AUDUSD" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("AUDUSD")}
            >
              <div className="forex-name">AUD/USD</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "GBPNZD" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("GBPNZD")}
            >
              <div className="forex-name">GBP/NZD</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "EURCHF" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("EURCHF")}
            >
              <div className="forex-name">EUR/CHF</div>
              <StarBorder></StarBorder>
            </div>
            <div
              onClick={() => onClickSymbolChange("EURJPY")}
              className={
                symbol === "EURJPY" ? "forex-rows-active" : "forex-rows"
              }
            >
              <div className="forex-name">EUR/JPY</div>

              <StarBorder></StarBorder>
            </div>
            <div
              onClick={() => onClickSymbolChange("GBPJPY")}
              className={
                symbol === "GBPJPY" ? "forex-rows-active" : "forex-rows"
              }
            >
              <div className="forex-name">GBP/JPY</div>

              <StarBorder></StarBorder>
            </div>
            <div
              onClick={() => onClickSymbolChange("USDCHF")}
              className={
                symbol === "USDCHF" ? "forex-rows-active" : "forex-rows"
              }
            >
              <div className="forex-name">USD/CHF</div>

              <StarBorder></StarBorder>
            </div>
          </>
        ) : (
          false
        )}

        {/* INDICIES */}
        {activeSection === "indicies" ? (
          <>
            <div
              className={
                symbol === "SPY" ? "indicies-rows-active" : "indicies-rows"
              }
              onClick={() => onClickSymbolChange("SPY")}
            >
              <div>
                <img src="/snp500.svg" width={"30px"} alt=""></img>
              </div>
              <div className="indicies-name">S&P 500</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "NASDAQ" ? "indicies-rows-active" : "indicies-rows"
              }
              onClick={() => onClickSymbolChange("NASDAQ")}
            >
              <div>
                <img src="/nasdaq.png" width={"30px"} alt=""></img>
              </div>
              <div className="indicies-name">NASDAQ 100</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "US30" ? "indicies-rows-active" : "indicies-rows"
              }
              onClick={() => onClickSymbolChange("US30")}
            >
              <div>
                <img src="/dow.png" width={"30px"} alt=""></img>
              </div>
              <div className="indicies-name">Dow Jones 30</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "GER30" ? "indicies-rows-active" : "indicies-rows"
              }
              onClick={() => onClickSymbolChange("GER30")}
            >
              <div>
                <img src="/dax30.png" width={"30px"} alt=""></img>
              </div>
              <div className="indicies-name">DAX Index 30</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "DXY" ? "indicies-rows-active" : "indicies-rows"
              }
              onClick={() => onClickSymbolChange("DXY")}
            >
              <div>
                <img src="/usdindex.jpeg" width={"30px"} alt=""></img>
              </div>
              <div className="indicies-name">US Dollar Index</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "VOLATILITY"
                  ? "indicies-rows-active"
                  : "indicies-rows"
              }
              onClick={() => onClickSymbolChange("VOLATILITY")}
            >
              <div>
                <img src="/volatity.jpg" width={"30px"} alt=""></img>
              </div>
              <div className="indicies-name">Volatility S&P 500 Index</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "FR40" ? "indicies-rows-active" : "indicies-rows"
              }
              onClick={() => onClickSymbolChange("FR40")}
            >
              <div>
                <img src="/cac40.jpg" width={"30px"} alt=""></img>
              </div>
              <div className="indicies-name">CAC 40</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "ITA40" ? "indicies-rows-active" : "indicies-rows"
              }
              onClick={() => onClickSymbolChange("ITA40")}
            >
              <div>
                <img src="/italy.png" width={"30px"} alt=""></img>
              </div>
              <div className="indicies-name">Italy 40</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "NL25" ? "indicies-rows-active" : "indicies-rows"
              }
              onClick={() => onClickSymbolChange("NL25")}
            >
              <div>
                <img src="/aex.jpg" width={"30px"} alt="" height={"20px"}></img>
              </div>
              <div className="indicies-name">Amsterdam AEX 25</div>
              <StarBorder></StarBorder>
            </div>
            <div
              className={
                symbol === "CN50" ? "indicies-rows-active" : "indicies-rows"
              }
              onClick={() => onClickSymbolChange("CN50")}
            >
              <div>
                <img src="/china.jpg" width={"30px"} alt=""></img>
              </div>
              <div className="indicies-name">FTSE China A50</div>
              <StarBorder></StarBorder>
            </div>
          </>
        ) : (
          false
        )}
      </div>
    </div>
  );
}

export default CryptocurrenciesWebSocket;
