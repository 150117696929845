import { Box, Container, Grid, Link } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router";
import PaymentFooter from "./components/PaymentFooter";

import "./index.scss";
function Footer() {
  const navigate = useNavigate();
  const mobileWidth = window.innerWidth;
  //asd
  return (
    <div className="footer-container">
      <Box className="footer-inner-container">
        <Container maxWidth="xl">
          <Grid container>
            {/* <LegalFooter /> */}
            <PaymentFooter />
            <Grid item className="grid-inner-container">
              <p>
                <strong className="footer-strong">RISK WARNING</strong>
                <br></br>
                CFDs are leveraged products that incur a high level of risk and
                can result in the loss of all your capital and may therefore not
                be suitable for all investors. You should not risk more than you
                are prepared to lose and before deciding to trade, please ensure
                you understand the risks involved, take the level of your
                experience into consideration and seek independent advice if
                necessary. Under no circumstances shall we have any liability to
                any person or entity for (a) any loss or damage in whole or part
                caused by, resulting from, or relating to any transactions
                related to CFDs or (b) any direct, indirect, special,
                consequential or incidental damages whatsoever.
              </p>
            </Grid>
          </Grid>
          <Box className="footer-container-box">
            <Box onClick={() => navigate("/privacy")}>
              <Link className="footer-container-link">Privacy Policy</Link>
            </Box>
            <Box onClick={() => navigate("/terms")}>
              <Link className="footer-container-link">Terms & Conditions</Link>
            </Box>
            <Box onClick={() => navigate("/aml")}>
              <Link className="footer-container-link">AML Policy</Link>
            </Box>
            <Box onClick={() => navigate("/deposit-policy")}>
              <Link className="footer-container-link">Deposit Policy</Link>
            </Box>
            <Box onClick={() => navigate("/refund-policy")}>
              <Link className="footer-container-link">Refund Policy</Link>
            </Box>
            <Box onClick={() => navigate("/security-policy")}>
              <Link className="footer-container-link">Security Policy</Link>
            </Box>
            <Box onClick={() => navigate("/risk-disclaimer")}>
              <Link className="footer-container-link">Risk Disclaimer</Link>
            </Box>
            <Box onClick={() => navigate("/kyc")}>
              <Link className="footer-container-link">KYC Policy</Link>
            </Box>
            <Box onClick={() => navigate("/security")}>
              <Link className="footer-container-link">Security</Link>
            </Box>
            <Box onClick={() => navigate("/mobile-privacy")}>
              <Link className="footer-container-link">Mobile Privacy</Link>
            </Box>
          </Box>
          <Box className="location-box-container">
            <span>
              © 2023 Pier Point Global | 32-36 Bd d'Avranches, 1160
              Bonnevoie-Nord-Verlorenkost Luxembourg
            </span>
          </Box>
        </Container>
      </Box>
    </div>
  );
}

export default Footer;
